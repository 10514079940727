import React from 'react';

import { TArticle } from '../../types';

import * as Styled from './styled';

type TProps = {
    data: TArticle;
};

export const ArticleCard: React.FC<TProps> = ({ data }) => {
    return (
        <Styled.Wrapper>
            <h1 className="title"> {data.name} </h1>
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </Styled.Wrapper>
    );
};
