export { getDynamicPageBySlug } from './api';

export { getArticlesDynamicPage } from './lib';

export type { TDynamicPage, TArticleRes, TArticleWithMain, TArticle } from './types';

export { ArticlesList } from './ui/articles-list';

export { ArticleCard } from './ui/article-card';

export { ArticleListItem } from './ui/article-list-item';
