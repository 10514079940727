import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';
import { defaultSettingsHtml } from '@/styles/mixins.styled';

export const Wrapper = styled.div`
    background: #fff;
    box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
    border-radius: 12px;
    padding: 32px;
    ${mq.lt.xl} {
        margin-bottom: 32px;
    }

    ${mq.lt.sm} {
        padding: 12px;
    }

    .title {
        font-size: ${({ theme }) => theme.fontSize.xl};
        font-weight: 500;
        margin-bottom: 12px;
        ${mq.lt.sm} {
            font-size: ${({ theme }) => theme.fontSize.lg};
        }
    }

    & > div:last-child {
        ${defaultSettingsHtml};
        line-height: 19px;
    }
`;
