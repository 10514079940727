import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    .items {
        display: flex;
        flex-direction: column;

        > div {
            padding: 32px;
            background: #fff;
            box-shadow: 0 4px 12px rgb(0 0 0 / 8%);

            &.calc-block {
                background: transparent;
                padding: 0;
                box-shadow: none;
                ${mq.lt.xl} {
                    margin-top: 32px;
                    margin-bottom: 32px;
                }
            }

            &:first-child {
                border-radius: 12px 12px 0 0;
            }

            &.mobile-start {
                ${mq.lt.lg} {
                    border-radius: 12px 12px 0 0;
                }
            }

            &.mobile-end {
                ${mq.lt.lg} {
                    border-radius: 0 0 12px 12px;
                }
            }

            &:last-child {
                border-radius: 0 0 12px 12px;
            }
        }
    }
`;
