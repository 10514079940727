import cn from 'classnames';
import { FC, ReactNode } from 'react';

import { ShowContentByBreakPoint } from '@/shared/ui/show-by-breakpoint';

import { TArticle } from '../../types';
import { ArticleListItem } from '../article-list-item';

import * as Styled from './styled';

type TProps = {
    data: TArticle[] | null;
    estimateForm: ReactNode;
};

export const ArticlesList: FC<TProps> = ({ data, estimateForm }) => {
    return (
        <Styled.Wrapper>
            <div className="items">
                {data &&
                    data.map((article, index) => {
                        const content: JSX.Element[] = [
                            <ArticleListItem
                                article={article}
                                className={cn({
                                    'mobile-end': index === 1,
                                    'mobile-start': index === 2,
                                })}
                                key={article.id.toString()}
                            />,
                        ];
                        if (index === 2) {
                            content.unshift(
                                <div className="calc-block" key={index.toString()}>
                                    <ShowContentByBreakPoint key="calc" showIf="xl">
                                        {estimateForm}
                                    </ShowContentByBreakPoint>
                                </div>,
                            );
                        }
                        return content;
                    })}
            </div>
        </Styled.Wrapper>
    );
};
