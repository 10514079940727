import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    .name,
    .content {
        font-style: normal;
        line-height: 19px;
        color: #333;
    }

    .name {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 12px;
    }

    .content {
        font-weight: 400;
        font-size: 16px;
    }

    .btn {
        margin-top: 16px;

        a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 16px;
            gap: 8px;
            width: 132px;
            height: 36px;
            background: #ececf0;
            border-radius: 4px;
        }
    }
    ${mq.lt.sm} {
        .content {
            font-size: ${({ theme }) => theme.fontSize.sm};
        }

        .btn {
            a {
                width: 100%;
            }
        }
    }
`;
