import { styled } from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 42px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1408px;
    flex: 1;
    position: relative;

    h1 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 120%;
    }
`;
