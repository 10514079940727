import { AxiosError } from 'axios';
import { NextPageContext } from 'next';

import { getMetaDataPage } from '@/shared/api/site/metadata-service';
import { LangSiteApi, NOT_FOUND_PAGE, SERVER_ERROR, TLangSite } from '@/shared/constants/settings';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';
import { TMetaData } from '@/shared/types/types';

import { getArticleBySlug, getArticleList } from '../api';
import { COUNT_SHOWN_ARTICLES } from '../constants';
import { TArticle, TArticleRes, TRandomArticles } from '../types';

export const getArticlesDynamicPage = async (context: NextPageContext): Promise<TArticleRes> => {
    const { locale, query } = context;
    let currentPage = 0;
    if (query?.slug && query?.slug[0].length < 5) {
        currentPage = parseInt(query?.slug[0] as string);
    }
    let response;

    switch (true) {
        case query.slug === undefined:
        case !isNaN(currentPage) && currentPage > 0:
            try {
                currentPage = currentPage === 0 ? 1 : currentPage;
                response = await Promise.all([
                    getArticleList(currentPage, LangSiteApi[locale as TLangSite]),
                    getMetaDataPage('category/stati', LangSiteApi[locale as TLangSite]),
                ]);

                if (response[0].data.length === 0 && currentPage !== 1) {
                    return {
                        statusCode: NOT_FOUND_PAGE,
                        template: 'list',
                    };
                }
            } catch (error) {
                customCaptureException(error, 'getArticlesDynamicPage');
                const err = error as AxiosError;
                return {
                    statusCode: err?.response?.status ?? SERVER_ERROR,
                    template: 'list',
                };
            }

            return {
                ...response[0],
                metaDataPage: {
                    ...response[1],
                    description: `${response?.[1]?.description ?? ''} - Страница № ${currentPage}`,
                    title: `${response?.[1]?.title ?? ''} - Страница № ${currentPage}`,
                } as TMetaData,
                statusCode: 200,
                template: 'list',
            };

        case query.slug !== undefined:
            try {
                const responseArticles = await Promise.all([
                    getArticleBySlug(query.slug as string),
                    getArticleList(1, LangSiteApi[locale as TLangSite]),
                ]);
                return {
                    data: {
                        articles: getRandomArticles({
                            arr: responseArticles[1].data,
                            count: COUNT_SHOWN_ARTICLES,
                            id: responseArticles[0].id,
                        }),
                        mainArticle: responseArticles[0],
                    },
                    statusCode: 200,
                    template: 'card',
                };
            } catch (err) {
                customCaptureException(err, 'getArticlesDynamicPage');
                const error = err as AxiosError;
                return {
                    statusCode: error?.response?.status ?? SERVER_ERROR,
                    template: 'card',
                };
            }

        default:
            return {
                data: null,
                statusCode: 404,
                template: 'card',
            };
    }
};

// Получаем рандомные статьи
export const getRandomArticles = ({
    arr: articles, // статьи
    count, // количество рандомных статьей
    id, // id основной статьи
}: TRandomArticles): TArticle[] => {
    const newArray: TArticle[] = [];

    if (articles.length === 0 || !id) {
        return newArray;
    }

    while (newArray.length !== count) {
        const randomIndex = Math.floor(Math.random() * articles.length);
        const isSimilar = newArray.some((elem) => elem.id === articles[randomIndex].id);

        if (articles[randomIndex].id !== id && !isSimilar) {
            newArray.push(articles[randomIndex]);
        }
    }

    return newArray;
};
