import Link from 'next/link';
import React, { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';

import { TArticle } from '../../types';

import * as Styled from './styled';

type TPops = {
    article: TArticle;
    className?: string;
};

export const ArticleListItem: FC<TPops> = ({ article, className }) => {
    const { t } = useTranslate();
    return (
        <Styled.Wrapper className={className}>
            <div className="name">
                <Link href={`/category/stati/${article.slug}`}>{article.name}</Link>
            </div>

            {article.preview_content && (
                <div className="content" dangerouslySetInnerHTML={{ __html: article.preview_content as string }} />
            )}

            <div className="btn">
                <Link href={`/category/stati/${article.slug}`}>{t('Читать  👀')}</Link>
            </div>
        </Styled.Wrapper>
    );
};
