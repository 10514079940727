import { useRouter } from 'next/router';
import { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Button } from '@/shared/ui/button';

import * as Styled from './styled';

export const Info: FC = () => {
    const { t } = useTranslate();
    const route = useRouter();
    return (
        <Styled.Wrapper>
            <div className="error-block">
                <div className="smile-absolute smile1">🎧</div>

                <div className="smile-absolute smile2">🧐</div>

                <div className="smile-absolute smile3">🤔</div>

                <div className="smile-absolute smile4">💻</div>

                <div className="action">
                    <div className="smile">😢</div>

                    <div className="text">{t('Мы не нашли такую страницу')}</div>

                    <div className="btn">
                        <Button onClick={() => route.push('/')} variant="gradient-brand-green">
                            {t('Вернуться на главную 🔥')}
                        </Button>
                    </div>
                </div>
            </div>
        </Styled.Wrapper>
    );
};
