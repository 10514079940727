import Head from 'next/head';
import Link from 'next/link';
import { FC } from 'react';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

import { NOT_FOUND_PAGE } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { LayoutDefault } from '@/shared/ui/layout/default';

import { Info } from './info';
import * as Styled from './styled';

type TProps = {
    statusCode: number;
};

export const ErrorPage: FC<TProps> = ({ statusCode }) => {
    const { t } = useTranslate();

    if (statusCode == NOT_FOUND_PAGE) {
        return (
            <LayoutDefault footer={<Footer />} header={<Header />}>
                <Head>
                    <title>{`${t('Ошибка')} ${statusCode}`}</title>
                </Head>
                <Info />
            </LayoutDefault>
        );
    }

    return (
        <LayoutDefault footer={<Footer />} header={<Header />}>
            <Head>
                <title>{`${t('Ошибка')} ${statusCode}`}</title>
            </Head>
            <Styled.Wrapper>
                <h1>
                    <span>
                        {t('Ошибка')} <span className="text-red">{statusCode}</span>
                    </span>
                </h1>

                <div className="error-body mt-4">
                    <p className="error-header mb-3">
                        {t(
                            'Страница, которую вы запросили, не доступна. Возможно, вы ошиблись при наборе адреса или перешли по неверной ссылке.',
                        )}
                    </p>

                    <p className="error-text mb-3">{t('* Проверьте правильность написания адреса')}</p>

                    <p className="error-text">
                        {t('* Воспользуйтесь главной страницей сайта')}{' '}
                        <Link className="text-link" href="/">
                            https://dengimarket.kz
                        </Link>
                    </p>
                </div>
            </Styled.Wrapper>
        </LayoutDefault>
    );
};
