import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    margin-top: 42px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1408px;
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;

    .error-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 69%;
        justify-content: center;
        margin: 0 auto;
        position: relative;

        .smile-absolute {
            font-style: normal;
            font-weight: 700;
            font-size: 36.5618px;
            line-height: 120%;
            position: absolute;

            &.smile1 {
                top: 0;
                left: 0;
                transform: rotateY(180deg) rotate(35deg);
            }

            &.smile2 {
                bottom: 0;
                left: 0;
                transform: rotateY(180deg) rotate(15deg);
                font-size: 29.9306px;
            }

            &.smile3 {
                top: 10%;
                right: 0;
                font-size: 22.3048px;
            }

            &.smile4 {
                bottom: 8%;
                right: 8%;
                font-size: 41.8411px;
                transform: rotateY(180deg) rotate(-15deg);
            }
        }

        .action {
            margin: 18px 0 105px;

            .smile {
                font-size: 64px;
                line-height: 120%;
                margin-bottom: 24px;
                text-align: center;
            }

            .text {
                font-weight: 500;
                font-size: 20px;
                line-height: 120%;
                text-align: center;
                color: #333;
                margin-bottom: 24px;
            }

            button {
                padding: 20px 56px;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
            }
        }

        ${mq.lt.sm} {
            width: 85%;

            .smile-absolute {
                &.smile1 {
                    top: 8%;
                    left: 0;
                }

                &.smile2 {
                    bottom: 6%;
                    left: 0;
                }

                &.smile3 {
                    top: 0%;
                    right: 0;
                }

                &.smile4 {
                    bottom: 0%;
                    right: 0%;
                }
            }

            .action {
                margin: 95px 0 130px;

                .text {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                }

                button {
                    padding: 15px 40px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }
`;
