import { AxiosError } from 'axios';

import { TReadParamsProperties, ResourceService } from '@/shared/api';
import { axiosSiteClient } from '@/shared/api/site';
import {
    CATEGORY_PAGE_ARTICLE_ID,
    NOT_FOUND_PAGE,
    SERVER_ERROR,
    SIZE_LIST_PAGE_ARTICLE,
    STATUS_CODE_OK,
} from '@/shared/constants/settings';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';

import { TDynamicPageRes, TDynamicPage, TArticleListRes, TArticle } from '../types';

export const DynamicPagesService = ResourceService('dynamic-pages', axiosSiteClient);

export const PagesService = ResourceService('pages', axiosSiteClient);

export const getDynamicPageBySlug = async (slug: string): Promise<TDynamicPageRes> => {
    let statusCode = STATUS_CODE_OK;

    try {
        const response = await DynamicPagesService.readOne<TDynamicPage, TReadParamsProperties>({
            identifier: `${slug}`,
        });
        if (response) {
            return {
                data: response,
                statusCode,
            };
        }
    } catch (err) {
        customCaptureException(err, 'getDynamicPageBySlug');
        const error = err as AxiosError;
        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
    }

    statusCode = NOT_FOUND_PAGE;
    return {
        data: null,
        statusCode,
    };
};

export const getArticleList = async (currentPage: number, localeId: string): Promise<TArticleListRes> => {
    const response = await PagesService.read<TArticleListRes, TReadParamsProperties>({
        params: {
            category: CATEGORY_PAGE_ARTICLE_ID,
            itemsPerPage: SIZE_LIST_PAGE_ARTICLE,
            locale: localeId,
            page: currentPage,
            pagination: true,
        },
    });

    return { ...response };
};

export const getArticleBySlug = async (slug: string): Promise<TArticle> => {
    const response = await PagesService.readOne<TArticle, TReadParamsProperties>({ identifier: slug });

    return response;
};
